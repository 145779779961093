<script>
import { sync } from 'vuex-pathify'

export default {
	lang: 'shop',
	data() {
		return {
			data: this.$srv('offerDrawer'),
		}
	},
	computed: {
		offersDrawer: sync('shop/offersDrawer'),
		listId() {
			return `${this.data.collectionId}-drawer-ofertas`
		},
		drawerWidth() {
			return this.$b.m ? '100%' : 400
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		v-model="offersDrawer"
		:color="$vars.base"
		:right="data.position == 'right'"
		fixed
		:width="drawerWidth"
		max-width="100%"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
	>
		<v-list-item class="px-3" :style="`color: ${$vars.basetext}`">
			<div class="font-weight-bold font-2 header">
				{{ data.title | lang }}
			</div>
			<v-spacer></v-spacer>
			<Button color="link" text @click="offersDrawer = false">
				<v-icon color="link">mdi-close</v-icon> {{ 'Cerrar' | lang }}
			</Button>
		</v-list-item>
		<v-divider />
		<v-list-item
			class="pt-4 d-flex justify-center"
			:style="`color: ${$vars.basetext}`"
			v-for="(item, i) of data.products"
			:key="i"
		>
			<OfferItem :product="item" :listId="listId" :listIndex="i" />
		</v-list-item>
		<v-list-item class="justify-center mt-2 mb-8">
			<Button color="cta" text :to="data.internalBtnLink" class="mt-2">
				{{ data.internalBtnText | lang }}
			</Button>
		</v-list-item>
	</v-navigation-drawer>
</template>

<style>
.offer-drawer-btn {
	position: fixed;
	bottom: 20px;
	z-index: 3;
}

.waves {
	position: fixed;
	bottom: 20px;
	transform-origin: center center;
	z-index: 2;
}

.wave1 {
	animation: waves 2.5s linear;
	animation-delay: 0.1s;
	animation-iteration-count: infinite;
}

.wave2 {
	animation: waves 2.5s linear 0.7s forwards;
	animation-iteration-count: infinite;
}

.wave3 {
	animation: waves 2.5s linear 1.3s forwards;
	animation-iteration-count: infinite;
}

@keyframes waves {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}

@media (max-width: 900px) {
	.offer-drawer-btn {
		bottom: 80px;
	}

	.waves {
		bottom: 80px;
	}
}
</style>
